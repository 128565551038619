import React from "react";
import Mycourse from "./MyCourse";
import Layout from "../AdminDashboard/Layout";
export default function Dashboard() {
  return (
    <Layout>
      <Mycourse />
    </Layout>
  );
}
