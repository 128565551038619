import React from 'react'
import Layout from './Layout'
import Settings from '../Profile/Setting'

export default function Setting() {
 
  return (
    <Layout>
        <Settings/>
    </Layout>
  )
}
