import React from 'react'
import CourseDetails from '../AdminDashboard/CourseDetails'

export default function Submission() {
  return (
    <CourseDetails>
        df
    </CourseDetails>
  )
}
